

// https://ionicframework.com/docs/theming/color-generator

/** Elywhere color variables **/
:root {
    --ion-color-primary: #0e7836;
    --ion-color-primary-rgb: 14,120,54;
    --ion-color-primary-contrast: #ffffff;
    --ion-color-primary-contrast-rgb: 255,255,255;
    --ion-color-primary-shade: #0c6a30;
    --ion-color-primary-tint: #26864a;
  
    --ion-color-primary-dark: #0e7836;
    --ion-color-primary-dark-rgb: 14,120,54;
    --ion-color-primary-dark-contrast: #ffffff;
    --ion-color-primary-dark-contrast-rgb: 255,255,255;
    --ion-color-primary-dark-shade: #0c6a30;
    --ion-color-primary-dark-tint: #26864a;

    --ion-color-secondary: #492887;
    --ion-color-secondary-rgb: 73,40,135;
    --ion-color-secondary-contrast: #ffffff;
    --ion-color-secondary-contrast-rgb: 255,255,255;
    --ion-color-secondary-shade: #402377;
    --ion-color-secondary-tint: #5b3e93;
  
    --ion-color-success: #0e7836;
    --ion-color-success-rgb: 14,120,54;
    --ion-color-success-contrast: #ffffff;
    --ion-color-success-contrast-rgb: 255,255,255;
    --ion-color-success-shade: #0c6a30;
    --ion-color-success-tint: #26864a;
  
    --ion-color-warning: #ffc409;
    --ion-color-warning-rgb: 255,196,9;
    --ion-color-warning-contrast: #000000;
    --ion-color-warning-contrast-rgb: 0,0,0;
    --ion-color-warning-shade: #e0ac08;
    --ion-color-warning-tint: #ffca22;
  
    --ion-color-danger: #eb445a;
    --ion-color-danger-rgb: 235,68,90;
    --ion-color-danger-contrast: #ffffff;
    --ion-color-danger-contrast-rgb: 255,255,255;
    --ion-color-danger-shade: #cf3c4f;
    --ion-color-danger-tint: #ed576b;
  
    --ion-color-dark: #0C3235;
    --ion-color-dark-rgb: 12,50,53;
    --ion-color-dark-contrast: #ffffff;
    --ion-color-dark-contrast-rgb: 255,255,255;
    --ion-color-dark-shade: #0b2c2f;
    --ion-color-dark-tint: #244749;
  
    --ion-color-gray: #989aa2;
    --ion-color-gray-rgb: 152,154,162;
    --ion-color-gray-contrast: #000000;
    --ion-color-gray-contrast-rgb: 0,0,0;
    --ion-color-gray-shade: #86888f;
    --ion-color-gray-tint: #a2a4ab;
  
    --ion-color-light: #EFEFEF;
    --ion-color-light-rgb: 239,239,239;
    --ion-color-light-contrast: #000000;
    --ion-color-light-contrast-rgb: 0,0,0;
    --ion-color-light-shade: #d2d2d2;
    --ion-color-light-tint: #f1f1f1;

    --ion-color-black: #000000;
    --ion-color-black-rgb: 0,0,0;
    --ion-color-black-contrast: #ffffff;
    --ion-color-black-contrast-rgb: 255,255,255;
    --ion-color-black-shade: #000000;
    --ion-color-black-tint: #1a1a1a;

    --ion-color-white: #ffffff;
    --ion-color-white-rgb: 255,255,255;
    --ion-color-white-contrast: #000000;
    --ion-color-white-contrast-rgb: 0,0,0;
    --ion-color-white-shade: #dadada;
    --ion-color-white-tint: #f9f9f9;

    --ion-color-charging: #039DE2;
    --ion-color-charging-rgb: 3,157,226;
    --ion-color-charging-contrast: #ffffff;
    --ion-color-charging-contrast-rgb: 255,255,255;
    --ion-color-charging-shade: #038ac7;
    --ion-color-charging-tint: #1ca7e5;

    --ion-color-toolbar: #f8f8f8;
    --ion-color-toolbar-rgb: 248,248,248;
    --ion-color-toolbar-contrast: #0e7836;
    --ion-color-toolbar-contrast-rgb: 14,120,54;
    --ion-color-toolbar-shade: #dadada;
    --ion-color-toolbar-tint: #f9f9f9;

    --ion-color-toolbar-button: #039DE2;
    --ion-color-toolbar-button-rgb: 3,157,226;
    --ion-color-toolbar-button-contrast: #ffffff;
    --ion-color-toolbar-button-contrast-rgb: 255,255,255;
    --ion-color-toolbar-button-shade: #038ac7;
    --ion-color-toolbar-button-tint: #1ca7e5;

}
.ion-color-primary{
    --ion-color-base: var(--ion-color-primary);
    --ion-color-base-rgb: var(--ion-color-primary-rgb);
    --ion-color-contrast: var(--ion-color-primary-contrast);
    --ion-color-contrast-rgb: var(--ion-color-primary-contrast-rgb);
    --ion-color-shade: var(--ion-color-primary-shade);
    --ion-color-tint: var(--ion-color-primary-tint);
}
.ion-color-primary-dark{
    --ion-color-base: var(--ion-color-primary-dark);
    --ion-color-base-rgb: var(--ion-color-primary-dark-rgb);
    --ion-color-contrast: var(--ion-color-primary-dark-contrast);
    --ion-color-contrast-rgb: var(--ion-color-primary-dark-contrast-rgb);
    --ion-color-shade: var(--ion-color-primary-dark-shade);
    --ion-color-tint: var(--ion-color-primary-dark-tint);
}
.ion-color-gray{
    --ion-color-base: var(--ion-color-gray);
    --ion-color-base-rgb: var(--ion-color-gray-rgb);
    --ion-color-contrast: var(--ion-color-gray-contrast);
    --ion-color-contrast-rgb: var(--ion-color-gray-contrast-rgb);
    --ion-color-shade: var(--ion-color-gray-shade);
    --ion-color-tint: var(--ion-color-gray-tint);
}
.ion-color-toolbar{
    --ion-color-base: var(--ion-color-toolbar);
    --ion-color-base-rgb: var(--ion-color-toolbar-rgb);
    --ion-color-contrast: var(--ion-color-toolbar-contrast);
    --ion-color-contrast-rgb: var(--ion-color-toolbar-contrast-rgb);
    --ion-color-shade: var(--ion-color-toolbar-shade);
    --ion-color-tint: var(--ion-color-toolbar-tint);
}
.ion-color-toolbar-button{
    --ion-color-base: var(--ion-color-toolbar-button);
    --ion-color-base-rgb: var(--ion-color-toolbar-button-rgb);
    --ion-color-contrast: var(--ion-color-toolbar-button-contrast);
    --ion-color-contrast-rgb: var(--ion-color-toolbar-button-contrast-rgb);
    --ion-color-shade: var(--ion-color-toolbar-button-shade);
    --ion-color-tint: var(--ion-color-toolbar-button-tint);
}
.ion-color-toolbar-contrast{
    --ion-color-base: var(--ion-color-toolbar-contrast);
}
.ion-color-black{
    --ion-color-base: var(--ion-color-black);
    --ion-color-base-rgb: var(--ion-color-black-rgb);
    --ion-color-contrast: var(--ion-color-black-contrast);
    --ion-color-contrast-rgb: var(--ion-color-black-contrast-rgb);
    --ion-color-shade: var(--ion-color-black-shade);
    --ion-color-tint: var(--ion-color-black-tint);
}
.ion-color-white{
    --ion-color-base: var(--ion-color-white);
    --ion-color-base-rgb: var(--ion-color-white-rgb);
    --ion-color-contrast: var(--ion-color-white-contrast);
    --ion-color-contrast-rgb: var(--ion-color-white-contrast-rgb);
    --ion-color-shade: var(--ion-color-white-shade);
    --ion-color-tint: var(--ion-color-white-tint);
}
.ion-color-success{
    --ion-color-base: var(--ion-color-success);
    --ion-color-base-rgb: var(--ion-color-success-rgb);
    --ion-color-contrast: var(--ion-color-success-contrast);
    --ion-color-contrast-rgb: var(--ion-color-success-contrast-rgb);
    --ion-color-shade: var(--ion-color-success-shade);
    --ion-color-tint: var(--ion-color-success-tint);
}


